import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import {
    onAuthStateChanged
} from 'firebase/auth';

import { auth } from './fb';

let appLoaded = false;

async function main() {
    await onAuthStateChanged(auth, (user) => {

        if (user) {
            // The user has just logged in (or already was)
            store.dispatch('fetchUser', user);
        } else {
            // The user has just logged out (or was not)
            store.dispatch('fetchUser', null);
        }

        if (!appLoaded) {
            appLoaded = true;
            createApp(App).use(store).mount('#app');
        }
    })
}

main();