import {
    signInWithCustomToken, browserLocalPersistence, browserSessionPersistence, signOut, Auth, User
  } from 'firebase/auth';
  
  import { Commit } from 'vuex';
  import * as fb from './fb';
  import TindeirbDB from './db';
  
  export default class TindeirbAuth {
    public static readonly instance: TindeirbAuth = new TindeirbAuth();
  
    private auth: Auth;
  
    private constructor() {
      this.auth = fb.auth;
    }
  
    public async login(token: string, remember: boolean): Promise<User> {
      if (remember) this.auth.setPersistence(browserLocalPersistence);
      // Set session persistence
      else this.auth.setPersistence(browserSessionPersistence);
  
      // Sign user in
      const { user } = await signInWithCustomToken(this.auth, token);
  
      return user;
    }
  
    public async logout(commit?: Commit): Promise<void> {
      // Destroy all connections to database
      TindeirbDB.instance.unBindAll(commit);
      await signOut(this.auth);
    }
  
    public getCurrentUser(): User | null {
      return this.auth.currentUser;
    }
  }