import { ref, query, onValue, Database, DatabaseReference, set, off } from "firebase/database";
import { Commit } from "vuex";
import * as fb from "./fb";

export default class TindeirbDB {
  public static readonly instance: TindeirbDB = new TindeirbDB();

  private references: {
    [path: string]: {
      reference: DatabaseReference;
      mutationName: string;
    };
  } = {};

  private db: Database;

  private constructor() {
    this.db = fb.db;
  }

  public async bindRef(commit: Commit, path: string, mutationName: string): Promise<void> {
    const reference = ref(this.db, path);
    this.references[path] = {
      reference,
      mutationName
    };

    return new Promise((resolve, reject) => {
      onValue(
        query(reference),
        snapshot => {
          const userData = snapshot.val();
          commit(mutationName, userData);
          resolve();
        },
        (er) => reject(er)
      );
    });
  }

  public async setRefValue(path: string, value: unknown): Promise<void> {
    const reference = ref(this.db, path);

    return new Promise((resolve, reject) => {
      set(reference, value).then(() => resolve()).catch((er) => reject(er));
    });
  }

  public unBindAll(commit?: Commit): void {
    Object.keys(this.references).forEach(path => {
      off(this.references[path].reference);
      if (commit != null) {
        commit(this.references[path].mutationName, undefined);
      }
    });
  }

  public getDb(): Database {
    return this.db;
  }
}