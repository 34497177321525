
import { Options, Vue } from 'vue-class-component';
import { authPopup } from '../utils';

import store, { TindeirbUser } from '../src/store';

@Options({
  watch: {
    user: function (user: TindeirbUser | null) {
      if (user) {
        this.remainingTime = Math.floor((new Date(user.shotgunTime).getTime() - new Date().getTime()) / 1000);
        // Start interval to check if shotgun time is reached
        this.interval = setInterval(() => {
          this.remainingTime = Math.floor((new Date(user.shotgunTime).getTime() - new Date().getTime()) / 1000);
          if (this.remainingTime <= 0) {
            clearInterval(this.interval);
            this.remainingTime = 0;
          }
        }, 1000);

      } else {
        clearInterval(this.interval);
      }
    }
  }
})
export default class App extends Vue {
  interval = 0;
  remainingTime = 1337;
  displayNames = false;
  displayOtherGroups = false;
  authLoading = false;

  get user() {
    return store.state.user;
  }

  get fillots() {
    return store.state.fillotsList;
  }

  textDate(dateStr: string) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year} à ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`;
  }

  created(): void {
    // Extract payload from GET param
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const ticket = urlParams.get("ticket");
    if (token && ticket) {
      window.history.replaceState({}, document.title, "/");
      this.authLoading = true;
      fetch(`/api/universal-auth/?ticket=${ticket}&token=${token}`)
        .then((response) => {
          response.json().then((responseData) => {
            if (responseData.status === "ok") {
              store.dispatch("login", {
                token: responseData.data.token,
                remember: true
              });
            } else {
              alert("Erreur lors de la connexion : " + responseData.message);
              this.authLoading = false;
            }
          });
        });
    }
  }

  selectFillot(group: string, index: number) {
    if (this.fillots && this.user) {
      if (this.fillots[group][index].parrain != null && this.fillots[group][index].parrain != this.user.uid) {
        alert('Ce fillot est déjà pris');
      } else if (this.fillots[group][index].parrain != null && this.fillots[group][index].parrain == this.user.uid) {
        alert('Impossible de désélectionner un fillot');
      } else if (group !== this.user.group) {
        alert('Impossible de sélectionner un fillot d\'une autre filière !');
      } else {
        store.dispatch('selectFillot', { group, index });
      }
    }
  }

  login() {
    const redirectUrl = window.location.origin /*+ "/api/auth/success"*/;
    const token = btoa(redirectUrl);
    const authUrl = `https://cas.bordeaux-inp.fr/login?service=https://aboin.vvv.enseirb-matmeca.fr/casAuth/?token=${token}@bordeaux-inp.fr`;
    /*return authPopup(authUrl, redirectUrl, ["ticket", "token"])
      .then((result) => {
        fetch(`/api/universal-auth/?ticket=${result.parameters.ticket}&token=${result.parameters.token}`)
          .then((response) => {
            response.json().then((responseData) => {
              if (responseData.status === "ok") {
                store.dispatch("login", {
                  token: responseData.data.token,
                  remember: true
                });
              } else {
                alert("Erreur lors de la connexion");
              }
            });
          });
      })
      .catch((error) => {
        alert("Erreur lors de la connexion : " + error.message);
      });*/
    window.location.href = authUrl;
  }

  logout() {
    this.authLoading = false;
    store.dispatch("logout");
  }
}
