

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

// Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyA5qMTKuaCRZgOrIDhnfsaCJBh7I4fsawM",
    authDomain: "univeirb.firebaseapp.com",
    databaseURL: "https://univeirb-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "univeirb",
    storageBucket: "univeirb.appspot.com",
    messagingSenderId: "596312633504",
    appId: "1:596312633504:web:f16cb03f667724eb6e59c5",
    measurementId: "G-YY53FGHVZT"
};

// Firebase project initialization
const firebaseProjet = initializeApp(firebaseConfig);

// Access and export Firebase services
const auth = getAuth(firebaseProjet);
const db = getDatabase(firebaseProjet);

export { auth, db };