import { createStore } from 'vuex'

import { User } from "@firebase/auth";

import TindeirbDB from "../db";
import TindeirbAuth from "../auth";

export interface TindeirbUser {
    displayName: string;
    email: string;
    group: string;
    providerId: string;
    uid: string;
    username: string;
    shotgunTime: string;
}

interface Fillot {
    nom: string,
    parrain: string | null
}

interface IState {
    user: TindeirbUser | undefined;
    fillotsList: {[key: string] : Fillot[]} | undefined;
}

export default createStore({
    state: {
        user: undefined,
        fillotsList: undefined
    } as IState,
    getters: {
    },
    mutations: {
        setUser(state, val: TindeirbUser) {
            state.user = val;
        },
        setFillotsList(state, val: {[key: string] : Fillot[]}) {
            state.fillotsList = val;
        },
    },
    actions: {
        async selectFillot({ commit }, {index, group}: {index: number, group: string}) {
            if (this.state.user != null && this.state.fillotsList != null) {
                if (new Date(this.state.user.shotgunTime) > new Date()) {
                    alert("Ce n'est pas encore l'heure du choix des fillots !");
                } else {
                    if (this.state.fillotsList[this.state.user.group].filter(f => f.parrain == this.state.user?.username).length > 0 && new Date() < new Date("2022-09-28 19:00:00 GMT+2")) {
                        alert("Vous avez déjà un fillot ! Veuillez attendre le 28/09 à 19h00 pour pouvoir en choisir un autre.");
                    } else if (this.state.fillotsList[this.state.user.group].filter(f => f.parrain == this.state.user?.username).length > 1) {
                        alert("Vous avez déjà deux fillots !");
                    } else {
                        const confirmation = confirm(`Voulez-vous vraiment sélectionner ${this.state.fillotsList[this.state.user.group][index].nom} comme fillot.e ?`);
                        if (confirmation) {
                            TindeirbDB.instance.setRefValue(`tindeirb-premium/fillots/${this.state.user.group}/${index}/parrain`, this.state.user.uid)
                                .then(() => {
                                    alert('Fillot.e sélectionné.e avec succès !');
                                }).catch((er) => {
                                    alert(`Une erreur est survenue : ${er}`);
                                });
                        }
                    }
                }
            }
        },
        async login(_, params: { token: string, remember: boolean }) {
            // Set session persistence then sign user in
            TindeirbAuth.instance.login(params.token, params.remember).catch(e => console.error('Login Error : ', e));
        },
        async fetchUser({ commit, dispatch }, user: User | null) {
            if (user != null) {
                const userPath = `tindeirb-premium/users/${user.uid}`;

                TindeirbDB.instance.bindRef(commit, userPath, "setUser")
                    .then(() => {
                        if (this.state.user) {
                            const fillotsPaths = `tindeirb-premium/fillots`;
                            return TindeirbDB.instance.bindRef(commit, fillotsPaths, "setFillotsList");
                        }
                    })
                    .catch(e => console.error('Fetch database error : ', e));
            }
        },
        async logout({ commit }) {
            await TindeirbAuth.instance.logout(commit);
        }
    },
    modules: {
    }
})
